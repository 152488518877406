import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import ai from "../../assets/images/services/ai.jpeg"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT"
                title="We Provide of AI Consulting Services, with over ten years of experience in outsourcing"
                description="Services in Artificial Intelligence and Data Management Workflows that are automated and intelligence that is built in are delivered. TEQT makes use of open and secure design processes and methodologies in order to leverage intelligent workflows." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={ai} alt="Custom Software Development" className="image" />

                    </div>
                    <div className="right-sec">
                        <h1>AI Consulting</h1>
                        <p className="mt-2">
                            Our AI consulting services empower businesses to harness the power of artificial intelligence to drive innovation, improve decision-making, and gain a competitive edge in their industries. Whether you're just starting to explore AI opportunities or looking to scale up existing AI initiatives, our team of experts is here to guide you through every step of the process.
                        </p>
                    </div>
                </div>

                <div className="service-sub-container">
                    <p className="mt-2">
                        We take a comprehensive approach to AI consulting, tailored to meet the unique needs and objectives of each client. Our consulting process typically includes the following steps:
                    </p>
                    <ul>
                        <li className="mt-2"><strong>Assessment:</strong> We conduct a thorough assessment of your business objectives, data assets, and existing AI capabilities.</li>
                        <li className="mt-2"><strong>Strategy Development:</strong> Based on our assessment, we develop a customized AI strategy that aligns with your goals and addresses your specific challenges.</li>
                        <li className="mt-2"><strong>Implementation:</strong> Our team helps you implement AI solutions, from data collection and model development to deployment and integration with existing systems.</li>
                        <li className="mt-2"><strong>Optimization:</strong> We continuously monitor and optimize your AI systems to ensure optimal performance, scalability, and alignment with your evolving business needs.</li>
                        <li className="mt-2"><strong>Training and Support:</strong> We provide training and ongoing support to your team to ensure they have the skills and knowledge to effectively leverage AI technologies.</li>
                    </ul>
                    <h3 className="mt-3">Why Choose Us?</h3>
                    <p className="mt-2">
                        With a proven track record of successful AI projects across various industries, our consultants bring a wealth of expertise and experience to every engagement. Whether you're looking to explore AI opportunities, solve specific business challenges, or drive innovation, we have the knowledge and resources to help you achieve your goals.
                    </p>
                </div>

                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AiConsultingPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
